<script setup>
import {ref} from 'vue';
import ApplicationLogo from '@/Components/Shared/ApplicationLogo.vue';
import {Link} from '@inertiajs/vue3';
import Container from "@/Components/Shared/Container.vue";

const showingNavigationDropdown = ref(false);
</script>

<template>
    <header>
        <nav class="bg-white shadow py-4">
            <Container>
                <div class="flex justify-between">
                    <Link href="/">
                        <ApplicationLogo class="block h-9 w-auto" />
                    </Link>

                    <div class="mx-auto"></div>

                    <div class="-mr-2 flex items-center sm:hidden">
                        <button @click="showingNavigationDropdown = ! showingNavigationDropdown" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                <path :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </Container>

            <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}" class="sm:hidden">

            </div>
        </nav>
    </header>
</template>
